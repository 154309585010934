<template>
  <div class=".gg-container-1">
      <div class="search-container">
     <!--   <div class="search-container-fn-input">
          <label>客情姓名：</label>
          <el-input
            size="small"
            placeholder="请输入客服姓名"
            prefix-icon="el-icon-search"
            v-model="searchParams.staff_name"
            clearable
            @change="getList"
          >
          </el-input>
        </div>-->

        <div class="search-container-fn-input">
          <label>客服工号：</label>
          <el-input
            size="mini"
            placeholder="请输入客服工号/姓名"
            prefix-icon="el-icon-search"
            v-model="searchParams.ad_expand"
            clearable
            @change="getList('restPage')"
          >
          </el-input>
        </div>
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
					@click="getList('restPage')"
				>
					<span>查找</span>
				</el-button>
        <!--<div class="search-container-fn-input">
          <label>手机号：</label>
          <el-input
            size="small"
            placeholder="请输入手机号"
            prefix-icon="el-icon-search"
            v-model="searchParams.mobile"
            clearable
            @change="getList"
          >
          </el-input>
        </div>-->
       <!-- <div class="search-container-fn-input">
          <label>商户：</label>
          <el-select
            v-model="searchParams.agent_id"
            placeholder="请选择"
            size="small"
            clearable
            @change="getList"
          >
            <template slot="prefix">
              <i
                class="el-icon-search"
                style="width: 25px; line-height: 32px;"
              ></i>
            </template>
            <el-option
              v-for="item in merchantList"
              :key="item.value"
              :label="item.merchant_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>-->
	
	
				<div style="margin-left: auto; display: flex">
				<div class="search-container-fn-input"
						 v-if="operateList.indexOf('update_table') > -1"
				>
					<el-button size="mini" type="primary" icon="el-icon-edit-outline"
										 style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
										 @click="handleEditTableHead">编辑表头
					</el-button>
				</div>
	
				<div class="search-container-fn-input"
						 v-if="operateList.indexOf('reset_query') > -1"
				>
					<el-button size="mini" type="primary" icon="el-icon-refresh"
										 style="padding: 6px 7px;
                              background-color: #2362FB; border-color: #2362FB"
										 @click="handleResetSearch">重置查找
					</el-button>
				</div>
				
        <div class="search-container-fn-input"
						 v-if="operateList.indexOf('add') > -1"
				>
          <el-button
            size="mini"
            type="primary"
            style="padding: 6px 7px 7px 8px;background-color: #2362FB; border-color: #2362FB"
            @click="handleAddCommercial('新增')"
          >
            <i class="el-icon-plus" style="font-size: 13px" />
            <span icon="el-icon-plus" style="margin-left: 5px; font-size: 12px"
              >新增</span
            >
          </el-button>
        </div>
				</div>

			</div>
			
     <!-- <el-table
        border
        v-loading="listLoading"
        :header-cell-style="{ 'text-align': 'center' }"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          fixed
          label="ID"
          type="index"
          align="center"
          width="80"
        >
        </el-table-column>
        <el-table-column fixed prop="staff_name" label="姓名" align="center">
        </el-table-column>
        <el-table-column prop="ad_expand" label="工号" align="center">
        </el-table-column>
        <el-table-column prop="agent_name" label="商户" align="center">
        </el-table-column>
        <el-table-column prop="head_portrait" label="图像" align="center">
          <template slot-scope="scope">
            <el-avatar
              :size="40"
              :src="scope.row.avatar || avatarSrc"
            ></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="手机号1" align="center">
        </el-table-column>
        <el-table-column prop="account_no" label="分机号" align="center">
        </el-table-column>
        <el-table-column prop="status" align="center" label="状态">
          <template slot-scope="scope">
            <el-switch
              class="switch"
              @change="changeExamine(scope.row)"
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              active-text="启用"
              inactive-text="禁用"
              active-color="#13ce66"
              inactive-color="#C8C8C8"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序" align="center">
        </el-table-column>
        <el-table-column prop="admin_name" label="创建人" align="center">
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间" align="center">
        </el-table-column>
        <el-table-column align="center" width="150" label="
 " fixed="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              style="padding: 7px 8px;"
              @click="handleEditCommercial(scope.$index, scope.row, '编辑')"
              >编辑
            </el-button>
            <el-button
              size="mini"
              type=""
              style="padding: 7px 8px; color: #fff; background-color:#FCC810; border-color: #FCC810; margin-left:5px;"
              @click="handleModifyUserPassword(scope.$index, scope.row)"
            >
              <i class="iconfont icon-suo" style="font-size: 13px" />
              <span style="margin-left: 5px">账密</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>-->
	
			<el-table
				border
				size="mini"
				v-loading="listLoading"
				:header-cell-style="{'text-align':'center'}"
				:data="tableData"
				height="625"
				style="width: 100%;z-index:0">
				<el-table-column
					type="index"
					align="center"
					width="50">
				</el-table-column>
				<template
					v-for="(column,index) in tableHead"
				>
					<el-table-column
						:prop="column.column_prop"
						:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
						:width="column.width ? column.width : '' "
						:key="index"
						align="center"
						v-if="column.field_type === 'textBtn' && column.visible === true "
						show-overflow-tooltip
					>
						<!--<template #header>
							<el-popover placement="bottom" title="" width="230" trigger="hover">
								<div slot="reference" class="search-header">
									<span class="search-title">{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
									<i style="margin-left: 5px" class="search-icon el-icon-search"></i>
								</div>
								<el-input
										size="mini"
										placeholder=""
										prefix-icon="el-icon-search"
										v-model="searchParams.buy_count"
										clearable
										@change="getList('restPage')"
									>
									</el-input>
							</el-popover>
						</template>-->
						<template slot-scope="scope">
							<a style="font-size: 12px; color: #2379fb"
								 @click.prevent="handleCustomerDetailEdit(scope.row)">
								{{scope.row[column.column_prop]}}
							</a>
						</template>
					</el-table-column>
					
					<el-table-column
						:prop="column.column_prop"
						:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
						:width="column.width ? column.width : '' "
						:key="index"
						align="center"
						v-else-if="column.field_type === 'select' && column.visible === true "
						show-overflow-tooltip
					>
						<template slot-scope="scope">
							<el-switch
								v-if="'status' == column.column_prop"
								class="switch"
								@change="changeExamine(scope.row)"
								v-model="scope.row.status"
								:active-value="1"
								:inactive-value="0"
								active-text="启用"
								inactive-text="禁用"
								active-color="#13ce66"
								inactive-color="#C8C8C8"
							>
							</el-switch>
							
							
							<el-switch
								v-if="'show_mobile_status' == column.column_prop"
								class="switch"
								@change="changeExamineShowMobileStatus(scope.row)"
								v-model="scope.row.show_mobile_status"
								:active-value="1"
								:inactive-value="0"
								active-text="启用"
								inactive-text="禁用"
								active-color="#13ce66"
								inactive-color="#C8C8C8"
							>
							</el-switch>
						</template>
					</el-table-column>
					
					<el-table-column
						:prop="column.column_prop"
						:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
						:width="column.width ? column.width : '' "
						:key="index"
						align="center"
						v-else-if="column.field_type === 'imageBtn' && column.visible === true "
						show-overflow-tooltip
					>
						<template slot-scope="scope">
							<div v-if="scope.row[column.column_prop].length>0" style="font-size: 12px; color: #2379fb"
									 @click="handleShowImage(scope.row[column.column_prop],  scope.row[column.column_prop][0])">
								<a>点击查看图片</a>
								<el-image
									style="width: 0px; height: 0px"
									:ref="scope.row[column.column_prop][0]"
									:src="imageUrl"
									:preview-src-list="scope.row[column.column_prop]"
									:z-index="9999">
								</el-image>
							</div>
							<div v-else>
								暂无
							</div>
						</template>
					</el-table-column>
					<el-table-column
						:sortable="tableHeadSortable.includes(column.column_prop)"
						:prop="column.column_prop"
						:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
						:width="column.width ? column.width : '' "
						:key="index"
						align="center"
						v-else-if="column.visible === true"
						show-overflow-tooltip
					>
						<template #header>
							<el-popover placement="bottom" title="" min-width="160" trigger="click" v-if = "searchTableHead.filter( item => item.name == column.column_prop).length > 0" >
							<span slot="reference" class="search-header" >
								<span
									class="search-title"
								>{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
								<i style="margin-left: 3px;font-weight: bolder; font-size: 20px"
									 class="el-icon-search"
									 :class="{isSearch: searchTableHead.filter(item => item.name == column.column_prop)[0].isSearch,
									  'el-icon-zoom-in': searchTableHead.filter(item => item.name == column.column_prop)[0].isSearch}"
								/>
							</span>
								<el-input
									v-if="'order_count' == column.column_prop"
									size="mini"
									placeholder=""
									prefix-icon="el-icon-search"
									v-model="searchParams.buy_count"
									clearable
									@change="handleSearch(column.column_prop ,searchParams.buy_count)"
								>
								</el-input>
								
								<el-input
									v-if="'mobile' == column.column_prop"
									size="mini"
									placeholder=""
									prefix-icon="el-icon-search"
									v-model="searchParams.mobile"
									clearable
									@change="handleSearch(column.column_prop ,searchParams.mobile)"
								>
								</el-input>
								
								
								<el-select
									v-else-if="'agent_name' == column.column_prop"
									style="width:200px"
									v-model="searchParams.agent_id"
									placeholder="请选择"
									size="mini"
									clearable
									@change="handleSearch(column.column_prop ,searchParams.agent_id)"
								>
									<template slot="prefix">
										<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
									</template>
									<el-option
										v-for="item in merchantList"
										:key="item.value"
										:label="item.merchant_name"
										:value="item.id">
									</el-option>
								</el-select>
						
						
								<el-select
									v-else-if="'visit_level' == column.column_prop"
									style="width:200px"
									v-model="searchParams.visit_level"
									placeholder="请选择"
									size="mini"
									clearable
									@change="handleSearch(column.column_prop ,searchParams.visit_level)"
								>
									<template slot="prefix">
										<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
									</template>
									<el-option
										v-for="item in optionsVisitLevel"
										:key="item.short_name"
										:label="item.short_name"
										:value="item.short_name">
									</el-option>
								</el-select>


								<el-select
									v-else-if="'from' == column.column_prop"
									style="width:200px"
									v-model="searchParams.from_id"
									placeholder="请输入客户来源"
									size="small"
									clearable
									@change="handleSearch(column.column_prop ,searchParams.from_id)"
								>
									<template slot="prefix">
										<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
									</template>
									<el-option
										v-for="item in customerFromOptions"
										:key="item.id"
										:label="item.name"
										:value="item.id">
									</el-option>
								</el-select>

								<el-date-picker
									v-else-if="'created_at' == column.column_prop"
									v-model="choiceDateCreateRecord"
									:default-time="['00:00:00', '23:59:59']"
									type="daterange"
									size="mini"
									value-format="yyyy-MM-dd HH-mm-ss "
									range-separator="至"
									start-placeholder="开始日期"
									end-placeholder="结束日期"
									@change="handleCreateRecordChangeDate()">
								</el-date-picker>


								<el-select
									v-else-if="'staff_name' == column.column_prop"
									style="width:200px"
									v-model="searchParams.staff_name"
									placeholder="请选择"
									size="mini"
									clearable
									@change="handleSearch(column.column_prop ,searchParams.staff_name)"
								>
									<template slot="prefix">
										<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
									</template>
									<el-option
										v-for="item in staffOptions"
										:key="item.id"
										:label="item.staff_name"
										:value="item.id">
									</el-option>
								</el-select>


							</el-popover>
							<span v-else>{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
						</template>
						<template slot-scope="scope">
							<span v-if="column.column_prop === 'buy_status'">{{scope.row[column.column_prop] === 1 ? '未购' : '已购' }}</span>
							<span v-else-if="column.column_prop === 'use_status'">
								{{scope.row[column.column_prop] | useStatusFormat }}
							</span>
							<span v-else-if="column.column_prop === 'is_wx'">
								{{scope.row[column.column_prop] == '0' ? '否' : '是'}}
							</span>
							<span v-else-if="column.column_prop === 'show_mobile_status'">
								{{scope.row[column.column_prop] == '0' ? '否' : '是'}}
							</span>
							<span v-else-if="column.column_prop === 'supervise_status'">
								{{scope.row[column.column_prop] | formatSuperviseStatus}}
							</span>
							<span v-else>{{scope.row[column.column_prop]}}</span>
						</template>
					</el-table-column>
				</template>
			</el-table>

			<pagination
        v-show="total > 0"
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
      />


    <AddCustomerRelationsDialogs
      v-if="isAddCustomerRelationsDialogsVisible"
      :isAddCustomerRelationsDialogsVisible.sync="
        isAddCustomerRelationsDialogsVisible
      "
      :options="options"
      @getList="getList"
      :info="info"
    ></AddCustomerRelationsDialogs>

		<!--编辑用户信息-->
		<edit-customer-relations-dialogs
			v-if="isEditCustomerRelationsDialogsVisible"
			:isEditCustomerRelationsDialogsVisible.sync="
        isEditCustomerRelationsDialogsVisible
      "
			:options="options"
			@getList="getList"
			:info="info"
		/>

    <!--账号密码修改弹出对话框-->
    <el-dialog
      title=""
      :visible.sync="changePasswordDialogFormVisible"
      width="480px"
      @closed="handleClose"
    >
      <span
        slot="title"
        style="font-size: 14px;color: #606266;font-weight: 700;"
        >修改密码</span
      >
      <el-form :model="changeEmployeeForm" :rules="rules" size="mini">
        <el-form-item label="登入名称" label-width="80px" prop="username">
          <el-input v-model="changeEmployeeForm.username" disabled></el-input>
        </el-form-item>
        <div style="margin-top: -16px; margin-left: 80px;">
          <i class="el-icon-warning" style="margin-right: 3px;color: orange"></i
          ><span style="color: #777;font-size: 12px">账号创建后不能更改</span>
        </div>
        <el-form-item label="新密码" label-width="80px" prop="password">
          <el-input
            type="password"
            v-model="changeEmployeeForm.password"
            autocomplete="off"
            show-password
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="mgr-20" size="mini" @click="handleCancel('修改')"
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="handleConfirm('修改')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

		<!--编辑表头-->
		<editTableHead :isActiveEditTableHead.sync="isActiveEditTableHead"
															@_getAdminFieldIndex="_getAdminFieldIndex"
															v-if="isActiveEditTableHead"
                   :table_type="table_type"
    >

		</editTableHead>

  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import AddCustomerRelationsDialogs from "./components/AddCustomerRelationsDialogs";
import editCustomerRelationsDialogs from './components/editCustomerRelationsDialogs';
import editTableHead from '@/components/editTableHead';
import {
  getStaffIndex,
  putStaffStatus,
  putStaffPwd,
  getCommonMerchants,
} from "@/api/cdm/cdm-guest.js";
import {getAdminFieldIndex, postExamineShowMobileStatus} from '@/api/ggkq';
import {mapGetters, mapState} from 'vuex';
import {getStaffList} from '@/api/patientManagement';

export default {
  name: "customerRelationsInfo",
  components: {
    Pagination,
    AddCustomerRelationsDialogs,
    editTableHead,
    editCustomerRelationsDialogs
  },
  computed: {
    ...mapGetters(["agentId", "adminId"]),
    ...mapState({
      is_admin: state => state.user.is_admin,
      operates: state => state.user.operates,
      table_options:state=>state.user.table_options,
    }),
  },
  async created() {
    console.log(this.$route.path)
    console.log(this.operates)
    this.operates.forEach(operate => {
      if(operate[this.$route.path]) {
        console.log(operate[this.$route.path].split(','))
        this.operateList.push(...operate[this.$route.path].split(','))
      }
    })
    await this._getStaffList()
    await this._getAdminFieldIndex()
    this.getList();
    this._getCommonMerchants();
  },
  data() {
    return {
      table_type:'customerRelationsInfo',
      searchTableHead: [
        {
          name : 'mobile',
          isSearch: false
        },
        // {
        //   name : 'agent_name',
        //   isSearch: false
        // },

        {
          name: 'created_at',
          isSearch: false
        },
        {
          name: 'staff_name',
          isSearch: false
        },

      ],
      tableHeadSortable: ['created_at','account_no'],
      tableHead: [],
      isActiveEditTableHead: false,
      staffOptions: [],
      choiceDateCreateRecord: [],

      avatarSrc: require("@/assets/avatar.png"),
      isAddCustomerRelationsDialogsVisible: false,
      isEditCustomerRelationsDialogsVisible: false,
      changePasswordDialogFormVisible: false,
      changePasswordValue: "",
      changeEmployeeForm: {
        username: "",
        password: "",
        id: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入登入名称",
            trigger: "blur",
          },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入登入密码",
            trigger: "blur",
          },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
      },
      options: "新增",
      info: {},
      searchParams: {
        staff_name: "",
        ad_expand: "",
        mobile: "",
        agent_id: "",
      },
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      total: 0,
      listLoading: false,
      tableData: [],
      merchantList: [],
      operateList: [],
    };
  },
  methods: {
    handleSearch(prop, value) {
      this.searchTableHead.forEach(item => {
        if (item.name == prop) {
          if (value == '') {
            item.isSearch = false
          } else {
            item.isSearch = true
          }
        }
      })
      this.getList('restPage')
    },
    handleResetSearch() {
      this.searchParams = {
        type: 'pre_stop',
        is_wx: '',
        visit_level: '',
        buy_count: '',
      }
      this.searchTableHead.forEach(item => {
        item.isSearch = false
      })
      this.choiceDateCreateRecord = []
      this.getList('restPage')
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true
    },
    async _getAdminFieldIndex () {
      // try {
      //   this.listLoading = true
      //   var params = {
      //     type: 'customerRelationsInfo'
      //   }
      //   const res = await getAdminFieldIndex(params)
        this.tableHead = this.table_options.customerRelationsInfo
      //   console.log(this.tableHead)
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false
      // }

    },

    async _getStaffList () {
      var params = {
        agent_id: this.agentId
      }
      var result = await getStaffList(params)
      console.log(result)
      this.staffOptions = result.data
    },

    _getCommonMerchants() {
      getCommonMerchants()
        .then((response) => {
          if (response.code == 200) {
            this.merchantList = response.data;
          } else {
            this.$message({
              message: response.msg,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$message({
            message: "网络问题",
            type: "error",
          });
        });
    },
    getList(rest) {
      if (rest == "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;

      let params = {
        ...this.searchParams,
      };
      params.page = this.listQuery.page;
      params.page_size = this.listQuery.limit;
      getStaffIndex(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },

    handleCustomerDetailEdit(row) {
      console.log(row)
      this.isEditCustomerRelationsDialogsVisible = true
			this.info = row
		},

    changeExamine(row) {
      //选择状态 (启用/禁用)
      console.log(row.status);
      this.listLoading = true;
      const data = {
        status: row.status,
      };
      putStaffStatus(row.id, data)
        .then((response) => {
          let data = response;
          console.log(data);
          if (data.code === 200) {
            this.getList();
            this.$message({
              type: "success",
              message: "修改成功!",
            });
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$message({
            type: "error",
            message: "修改失败!",
          });
          this.listLoading = false;
        });
    },
    changeExamineShowMobileStatus(row) {
      console.log(row.show_mobile_status);
      this.listLoading = true;
      const data = {
        show_mobile_status: row.show_mobile_status,
      };
      postExamineShowMobileStatus(row.id, data)
        .then((response) => {
          let data = response;
          console.log(data);
          if (data.code === 200) {
            this.getList();
            this.$message({
              type: "success",
              message: "修改成功!",
            });
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$message({
            type: "error",
            message: "修改失败!",
          });
          this.listLoading = false;
        });
		},
    _putStaffPwd(id, data) {
      putStaffPwd(id, data)
        .then((response) => {
          if (response.code == 200) {
            this.$message({
              type: "success",
              message: "修改密码成功",
            });
          } else {
            this.$message({
              type: "error",
              message: response.message,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$message({
            type: "error",
            message: "网络问题",
          });
        });
    },
    handleAddCommercial(options) {
      this.options = options;
      this.isAddCustomerRelationsDialogsVisible = true;
    },
    handleEditCommercial(index, row, options) {
      this.options = options;
      this.info = row;
      this.isAddCustomerRelationsDialogsVisible = true;
    },
    handleModifyUserPassword(index, row) {
      this.changeEmployeeForm.username = row.staff_name;
      this.changeEmployeeForm.id = row.id;
      this.changePasswordDialogFormVisible = true;
    },
    handleClose() {
      this.changeEmployeeForm.username = "";
      this.changeEmployeeForm.password = "";
    },
    handleConfirm(operation) {
      const data = {
        password: this.changeEmployeeForm.password,
      };
      this._putStaffPwd(this.changeEmployeeForm.id, data);
      this.changePasswordDialogFormVisible = false;
    },
    handleCancel(operation) {
      this.changePasswordDialogFormVisible = false;
    },

    handleCreateRecordChangeDate () {
      this.searchParams.create_at = ""
      var _tmp0
      var _tmp1
      if (this.choiceDateCreateRecord) {
        _tmp0 = this.choiceDateCreateRecord[0].substring(0, 10)
        _tmp1 = this.choiceDateCreateRecord[1].substring(0, 10)
        this.searchParams.create_at += _tmp0 + ","
        this.searchParams.create_at += _tmp1
      } else {
        this.searchParams.create_at = ""
      }
      this.searchTableHead.forEach(item => {
        if (item.name == 'created_at') {
          if (this.searchParams.create_at == '') {
            item.isSearch = false
          } else {
            item.isSearch = true
          }
        }
      })
      this.getList('restPage')
    },
  },
};
</script>

<style scoped lang="scss">
	
	.isSearch {
		color: #F56C6C;
	}
	
	.search-icon {
		color: #F56C6C;
	}
	
.header {
  display: flex;
  align-items: center;
  height: 26px;
  margin-bottom: 10px;

  i {
    width: 25px;
    height: 25px;
    background-color: #2632fb;
    color: white;
    line-height: 25px;
    text-align: center;
    margin-right: 8px;
    border-radius: 2px;
  }
}

/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core,
  .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
