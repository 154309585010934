<template>
	<div class="">
		<el-dialog v-loading="listLoading" :visible.sync="isEditCustomerRelationsDialogsVisible" width="640px" inline
							 :before-close="handleCancel">
			<span slot="title" style="font-size: 14px;color: #606266;font-weight: 700;">编辑</span>
			
			
			<el-form :model="ruleForm" :rules="rules" size="mini" :inline="true" label-position="top" ref="ruleForm">
				
				
				<el-form-item label="姓名" prop="staff_name" class="from-item-second mgr-40">
					<el-input v-model.trim="ruleForm.staff_name" autocomplete="off" clearable placeholder="请填写姓名">
					</el-input>
				</el-form-item>
				<el-form-item label="分机号" prop="account_no" class="from-item-second ">
					<el-input v-model.trim="ruleForm.account_no" autocomplete="off" clearable placeholder="请填写分机号" type="number">
					</el-input>
				</el-form-item>
				
				<el-form-item label="手机号" prop="mobile" class="from-item-second mgr-40">
					<el-input v-model.trim="ruleForm.mobile" maxlength="11" autocomplete="off" clearable
										placeholder="请填写手机号"></el-input>
				</el-form-item>
				
				<el-form-item label="排序" prop="sort" class="from-item-second ">
					<el-input v-model.trim.number="ruleForm.sort" autocomplete="off" clearable
										placeholder="请填写排序,必须是数字"></el-input>
				</el-form-item>
				
			</el-form>
			
			
			<div slot="footer" class="dialog-footer">
				<el-button class="mgr-20" size="mini" @click="handleCancel">取 消</el-button>
				<el-button size="mini" type="primary" @click="submitForm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
  mapGetters
} from "vuex"
import customUpload from "@/components/customUpload";
import {
  getCommonMerchants,
  postStaffSave,
  putStaffUpdate
} from "@/api/cdm/cdm-guest.js"

export default {
  name: "editCustomerRelationsDialogs",

  props: {
    isEditCustomerRelationsDialogsVisible: {
      type: Boolean,
      default: false
    },
    options: {
      type: String,
      default: '新增'
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(["userType", "adminId"])
  },
  created() {
    this._getCommonMerchants()
		
  },
  data() {
    let validateMobile = (rule, value, callback) => {
      let checkMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
      if (checkMobile.test(value)) {
        callback()
      } else {
        callback(new Error('请填写正确手机号'))
      }
    }

    let ruleForm = {
      agent_id: '',
      staff_name: '',
      ad_expand: '',
      mobile: '',
      sort: '',
      status: 1,
      head_portrait: '',
      account_no: '',
    }

    
		ruleForm = this.info
    
    return {
      patientAvatar: [],
      merchantList: [],
      listLoading: false,
      ruleForm,
      rules: {
        staff_name: [{
          required: true,
          message: '请填写姓名',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '请填写手机号',
          trigger: 'blur'
        },
          {
            validator: validateMobile,
            message: '请填写手机号',
            trigger: 'blur'
          }
        ],
        sort: [{
          required: true,
          message: '请填写排序,必须是数字',
          trigger: 'blur'
        },
          {
            type: 'number',
            message: '必须是数字',
            trigger: 'blur'
          }
        ],
        
      }
    }
  },
  methods: {
    _getCommonMerchants() {
      getCommonMerchants().then(response => {
        if (response.code == 200) {
          this.merchantList = response.data
        } else {
          this.$message({
            message: response.msg,
            type: 'error'
          })
        }
      }).catch(error => {
        console.log(error)
        this.$message({
          message: '网络问题',
          type: 'error'
        })
      })
    },
    _postStaffSave(data) {
      postStaffSave(data).then(response => {
        if (response.code == 200) {
          this.$message({
            type: 'success',
            message: '增加成功'
          })
          this.handleConfirm()
        } else {
          this.$message({
            type: 'error',
            message: response.message
          })
        }
      })
    },
    _putStaffUpdate(id, data) {
      putStaffUpdate(id, data).then(response => {
        if (response.code == 200) {
          this.$message({
            type: 'success',
            message: '修改成功'
          })
          this.handleConfirm()
        } else {
          this.$message({
            type: 'error',
            message: response.message || response.msg
          })
        }
      }).catch(err => {
        this.$message({
          type: 'error',
          message: err.message || err.msg
        })
      })
    },
    submitForm(formName) {
      console.log(this.$refs[formName])
      this.$refs[formName].validate((valid) => {
        if (valid) {
					this._putStaffUpdate(this.info.id, this.ruleForm)
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    onSuccess(file, name) {
      this.ruleForm.head_portrait = file;
    },
    handleSourceChangeSelect(value) {
      console.log(value)
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleConfirm() {
      this.resetForm('ruleForm')
      this.$emit('getList')
      this.$emit('update:isEditCustomerRelationsDialogsVisible', false)
    },
    handleCancel(e) {
      this.resetForm('ruleForm')
      this.$emit('update:isEditCustomerRelationsDialogsVisible', false)
    },
  }
}
</script>

<style scoped lang="scss">
	.mgl-40 {
		margin-left: 40px !important;
	}
	
	.mgr-40 {
		margin-right: 40px !important;
	}
	
	/*dialog对话框样式调整*/
	/deep/ .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	/deep/ .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	/*dialog对话框样式调整*/
	/deep/ .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	/deep/ .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	
	/*弹出框表单配置*/
	/deep/ .el-form-item__label {
		padding-bottom: 0;
	}
	
	/deep/ .el-form-item__content {
		width: 280px;
	}
	
	.from-item-second {
		margin-left: 0;
		margin-right: 0;
	}
	
	/deep/ .from-item-third {
		.el-form-item__content {
			width: 590px;
		}
	}
	
	/deep/ .el-cascader {
		width: 280px;
	}
	
	/deep/ .el-select {
		width: 280px !important;
	}
	
	/deep/ .el-date-editor {
		width: 280px !important;
	}
	
	/*select 宽度配置*/
	/deep/ .el-select {
		width: 85px;
	}
	/deep/ input::-webkit-outer-spin-button,
	/deep/ input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
	}
	/deep/ input[type="number"] {
		-moz-appearance: textfield;
	}
</style>
